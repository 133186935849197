import { defineStore } from 'pinia'
import Vue from "vue";
import {validateResponse} from "@/utils";
import {createClient, deleteClient, getClient, getClients, updateClient} from "@/services/client-service";
import router from "@/router";
import {getCurrentBusiness, updateCurrentBusiness} from "@/services/business-service";

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useBusinessStore = defineStore('business', {
    state: () => ({
        loading: false,
        currentBusiness: JSON.parse(localStorage.getItem('currentBusiness'))
    }),
    actions: {
        async getCurrentBusiness() {
            try {
                this.loading = true
                const response = await getCurrentBusiness()
                this.currentBusiness = validateResponse(response, 200)
                this.loading = false
            } catch (e) {
                this.loading = false
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
        async updateCurrentBusiness(values) {
            try {
                this.loading = true
                const response = await updateCurrentBusiness(values)
                const currentBusiness = validateResponse(response, 200)
                this.currentBusiness = currentBusiness
                localStorage.setItem('currentBusiness', JSON.stringify(currentBusiness))
                this.loading = false
                Vue.$toast.success("Entitatea juridica a fost modificata cu succes");
            } catch (e) {
                this.loading = false
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
    }
})
