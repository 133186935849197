<template>
  <div>

    <!-- Header Background Image -->
    <div class="profile-nav-bg" style="background-image: url('images/bg-profile.jpg')"></div>
    <!-- / Header Background Image -->

    <!-- User Profile Card -->
    <a-card :bordered="false" class="card-profile-head" :bodyStyle="{padding: 0,}">
      <template #title>
        <a-row type="flex" align="middle">
          <a-col :span="12" class="col-info">
            <div class="avatar-info">
              <h4 class="font-semibold m-0">{{userStore.currentUser.fullName}}</h4>
            </div>
          </a-col>
        </a-row>
      </template>
    </a-card>

    <a-card style="padding: 10px;">
      <a-row>
        <a-col :span="12">
          <h3>Detalii utilizator</h3>
        </a-col>
        <a-col :span="12">
          <a-button @click="handleSetEditableUser" type="primary" style="float: right; margin-right: 10px" size="small">Editeaza</a-button>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :md="12" :xs="24">
          <a-input v-model:value="values.fullName" addon-before="Nume complet" class="input-left" :disabled="!editableUser">
            <template #prefix>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
              </svg>
            </template>
          </a-input>
        </a-col>
        <a-col :span="2"></a-col>
        <a-col :md="12" :xs="24">
          <a-input v-model:value="values.email" addon-before="Email" class="input-left" :disabled="!editableUser">
            <template #prefix>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
              </svg>
            </template>
          </a-input>
        </a-col>
      </a-row>
      <a-button @click="handleSaveUserButton" block type="primary" :loading="userStore.loading" :disabled="!editableUser" style="margin-top: 20px;">Salveaza</a-button>
    </a-card>

    <a-card style="padding: 10px; margin-top: 20px">
      <a-row>
        <a-col :span="12">
          <h3>Detalii entitate juridica</h3>
        </a-col>
        <a-col :span="12">
          <a-button @click="handleSetEditableBusiness" type="primary" style="float: right; margin-right: 10px" size="small">Editeaza</a-button>
        </a-col>
      </a-row>

      <a-row class="form-row" :gutter="18">
        <a-col :md="12" :xs="24">
          <a-input v-model:value="businessValues.name" addon-before="Nume brand" class="input-left" :disabled="!editableBusiness">
            <template #prefix>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bootstrap-fill" viewBox="0 0 16 16">
                <path d="M6.375 7.125V4.658h1.78c.973 0 1.542.457 1.542 1.237 0 .802-.604 1.23-1.764 1.23H6.375zm0 3.762h1.898c1.184 0 1.81-.48 1.81-1.377 0-.885-.65-1.348-1.886-1.348H6.375v2.725z"/>
                <path d="M4.002 0a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4h-8zm1.06 12V3.545h3.399c1.587 0 2.543.809 2.543 2.11 0 .884-.65 1.675-1.483 1.816v.1c1.143.117 1.904.931 1.904 2.033 0 1.488-1.084 2.396-2.888 2.396H5.062z"/>
              </svg>
            </template>
          </a-input>
        </a-col>
        <a-col :span="2"></a-col>
        <a-col :md="12" :xs="24">
          <a-input v-model:value="businessValues.fullName" addon-before="Nume entitate juridica" class="input-left" :disabled="!editableBusiness">
            <template #prefix>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"/>
                <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"/>
              </svg>
            </template>
          </a-input>
        </a-col>
      </a-row>

      <a-row class="form-row" :gutter="16">
        <a-col :md="12" :xs="24">
          <a-input v-model:value="businessValues.cif" addon-before="CIF" class="input-left" :disabled="!editableBusiness">
            <template #prefix>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-braces-asterisk" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.114 8.063V7.9c1.005-.102 1.497-.615 1.497-1.6V4.503c0-1.094.39-1.538 1.354-1.538h.273V2h-.376C2.25 2 1.49 2.759 1.49 4.352v1.524c0 1.094-.376 1.456-1.49 1.456v1.299c1.114 0 1.49.362 1.49 1.456v1.524c0 1.593.759 2.352 2.372 2.352h.376v-.964h-.273c-.964 0-1.354-.444-1.354-1.538V9.663c0-.984-.492-1.497-1.497-1.6ZM14.886 7.9v.164c-1.005.103-1.497.616-1.497 1.6v1.798c0 1.094-.39 1.538-1.354 1.538h-.273v.964h.376c1.613 0 2.372-.759 2.372-2.352v-1.524c0-1.094.376-1.456 1.49-1.456v-1.3c-1.114 0-1.49-.362-1.49-1.456V4.352C14.51 2.759 13.75 2 12.138 2h-.376v.964h.273c.964 0 1.354.444 1.354 1.538V6.3c0 .984.492 1.497 1.497 1.6ZM7.5 11.5V9.207l-1.621 1.621-.707-.707L6.792 8.5H4.5v-1h2.293L5.172 5.879l.707-.707L7.5 6.792V4.5h1v2.293l1.621-1.621.707.707L9.208 7.5H11.5v1H9.207l1.621 1.621-.707.707L8.5 9.208V11.5h-1Z"/>
              </svg>
            </template>
          </a-input>
        </a-col>
        <a-col :span="2"></a-col>
        <a-col :md="12" :xs="24">
          <a-input v-model:value="businessValues.regCom" addon-before="Reg. com." class="input-left" :disabled="!editableBusiness">
            <template #prefix>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-briefcase-fill" viewBox="0 0 16 16">
                <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z"/>
                <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"/>
              </svg>
            </template>
          </a-input>
        </a-col>
      </a-row>

      <a-row class="form-row" :gutter="16">
        <a-col :md="12" :xs="24">
          <a-input v-model:value="businessValues.county" addon-before="Judet" class="input-left" :disabled="!editableBusiness">
            <template #prefix>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-map-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.598-.49L10.5.99 5.598.01a.5.5 0 0 0-.196 0l-5 1A.5.5 0 0 0 0 1.5v14a.5.5 0 0 0 .598.49l4.902-.98 4.902.98a.502.502 0 0 0 .196 0l5-1A.5.5 0 0 0 16 14.5V.5zM5 14.09V1.11l.5-.1.5.1v12.98l-.402-.08a.498.498 0 0 0-.196 0L5 14.09zm5 .8V1.91l.402.08a.5.5 0 0 0 .196 0L11 1.91v12.98l-.5.1-.5-.1z"/>
              </svg>
            </template>
          </a-input>
        </a-col>
        <a-col :span="2"></a-col>
        <a-col :md="12" :xs="24">
          <a-input v-model:value="businessValues.city" addon-before="Oras" class="input-left" :disabled="!editableBusiness">
            <template #prefix>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"/>
              </svg>
            </template>
          </a-input>
        </a-col>
      </a-row>

      <a-row class="form-row" :gutter="16">
        <a-col :md="12" :xs="24">
          <a-input v-model:value="businessValues.address" addon-before="Adresa" class="input-left" :disabled="!editableBusiness">
            <template #prefix>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
              </svg>
            </template>
          </a-input>
        </a-col>
        <a-col :span="2"></a-col>
        <a-col :md="12" :xs="24">
          <a-input v-model:value="businessValues.bank" addon-before="Banca" class="input-left" :disabled="!editableBusiness">
            <template #prefix>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bank2" viewBox="0 0 16 16">
                <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z"/>
              </svg>
            </template>
          </a-input>
        </a-col>
      </a-row>

      <a-row class="form-row" :gutter="16">
        <a-col :md="12" :xs="24">
          <a-input v-model:value="businessValues.iban" addon-before="IBAN" class="input-left" :disabled="!editableBusiness">
            <template #prefix>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-safe2" viewBox="0 0 16 16">
                <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h12A1.5 1.5 0 0 1 16 2.5v12a1.5 1.5 0 0 1-1.5 1.5h-12A1.5 1.5 0 0 1 1 14.5V14H.5a.5.5 0 0 1 0-1H1V9H.5a.5.5 0 0 1 0-1H1V4H.5a.5.5 0 0 1 0-1H1v-.5zM2.5 2a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h12a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5h-12z"/>
                <path d="M5.035 8h1.528c.047-.184.12-.357.214-.516l-1.08-1.08A3.482 3.482 0 0 0 5.035 8zm1.369-2.303 1.08 1.08c.16-.094.332-.167.516-.214V5.035a3.482 3.482 0 0 0-1.596.662zM9 5.035v1.528c.184.047.357.12.516.214l1.08-1.08A3.482 3.482 0 0 0 9 5.035zm2.303 1.369-1.08 1.08c.094.16.167.332.214.516h1.528a3.483 3.483 0 0 0-.662-1.596zM11.965 9h-1.528c-.047.184-.12.357-.214.516l1.08 1.08A3.483 3.483 0 0 0 11.965 9zm-1.369 2.303-1.08-1.08c-.16.094-.332.167-.516.214v1.528a3.483 3.483 0 0 0 1.596-.662zM8 11.965v-1.528a1.989 1.989 0 0 1-.516-.214l-1.08 1.08A3.483 3.483 0 0 0 8 11.965zm-2.303-1.369 1.08-1.08A1.988 1.988 0 0 1 6.563 9H5.035c.085.593.319 1.138.662 1.596zM4 8.5a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0zm4.5-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
              </svg>
            </template>
          </a-input>
        </a-col>
      </a-row>
      <a-button @click="handleSaveBusinessButton" block type="primary" :loading="businessStore.loading" :disabled="!editableBusiness" style="margin-top: 20px;">Salveaza</a-button>
    </a-card>

  </div>
</template>

<script>
import {useUserStore} from "@/stores/user-store";
import {useBusinessStore} from "@/stores/business-store";
import _ from 'lodash'

export default {
  name: 'UserProfile',
  setup () {
    const userStore = useUserStore()
    const businessStore = useBusinessStore()

    return {
      userStore,
      businessStore
    }
  },
  data () {
    return {
      currentUser: {},
      currentBusiness: {},
      editableUser: false,
      editableBusiness: false,
      values: {
        fullName: null,
        email: null
      },
      businessValues: {
        name: null,
        fullName: null,
        cif: null,
        regCom: null,
        address: null,
        city: null,
        county: null,
        iban: null,
        bank: null,
      }
    }
  },
  async mounted() {
    await this.userStore.getCurrentUser()
    await this.businessStore.getCurrentBusiness()
    this.currentUser = _.cloneDeep(this.userStore.currentUser)//JSON.parse(localStorage.getItem('currentUser'))
    this.currentBusiness = _.cloneDeep(this.businessStore.currentBusiness)//JSON.parse(localStorage.getItem('currentBusiness'))
    Object.keys(this.values).forEach(key => {
      this.values[key] = this.userStore.currentUser[key]
    })
    Object.keys(this.businessValues).forEach(key => {
      this.businessValues[key] = this.businessStore.currentBusiness[key]
    })
  },
  methods: {
    handleSetEditableUser () {
      this.editableUser = !this.editableUser
      if (!this.editableUser) {
        Object.keys(this.values).forEach(key => {
          this.values[key] = this.currentUser[key]
        })
      }
    },
    handleSetEditableBusiness () {
      this.editableBusiness = !this.editableBusiness
      if (!this.editableBusiness) {
        Object.keys(this.businessValues).forEach(key => {
          this.businessValues[key] = this.currentBusiness[key]
        })
      }
    },
    async handleSaveUserButton () {
      await this.userStore.updateCurrentUser(this.values)
      this.currentUser = _.cloneDeep(this.userStore.currentUser)
      Object.keys(this.values).forEach(key => {
        this.values[key] = this.currentUser[key]
      })
      this.editableUser = false
    },
    async handleSaveBusinessButton () {
      await this.businessStore.updateCurrentBusiness(this.businessValues)
      this.currentBusiness = _.cloneDeep(this.businessStore.currentBusiness)
      Object.keys(this.businessValues).forEach(key => {
        this.businessValues[key] = this.currentBusiness[key]
      })
      this.editableBusiness = false
    }
  },
}
</script>

<style scoped>
.form-row {
  margin-top: 20px;
}
</style>
